export function capitalize(value: string | undefined): string {
  if (!value) {
    return ""; // Return empty string if destination is undefined or empty
  }

  return value
    .trim() // Remove any leading/trailing spaces
    .split(" ") // Split the string into words
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize each word
    )
    .join(" "); // Join the words back into a single string
}
