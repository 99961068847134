export interface IPartnerLinks {
  expedia: { link: string };
  hotels: { link: string };
}

export const topDestinationsLinks: Record<string, IPartnerLinks> = {
  Dakar: {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_DAKAR_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_DAKAR_LINK || "",
    },
  },
  "Saly Portudal": {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_SALY_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_SALY_LINK || "",
    },
  },
  "Cap Skirring": {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_CAP_SKIRRING_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_CAP_SKIRRING_LINK || "",
    },
  },
  "Île de Gorée": {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_GOREE_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_GOREE_LINK || "",
    },
  },
  "Saint-Louis": {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_SAINT_LOUIS_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_SAINT_LOUIS_LINK || "",
    },
  },
  Paris: {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_PARIS_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_PARIS_LINK || "",
    },
  },
  Dubai: {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_DUBAI_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_DUBAI_LINK || "",
    },
  },
  Marrakech: {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_MARRAKECH_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_MARRAKECH_LINK || "",
    },
  },
  Istanbul: {
    expedia: {
      link: process.env.REACT_APP_EXPEDIA_ISTANBUL_LINK || "",
    },
    hotels: {
      link: process.env.REACT_APP_HOTELS_ISTANBUL_LINK || "",
    },
  },
  // Add other destinations similarly...
};
