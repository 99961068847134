import React from "react";
import ReactGA from "react-ga4";
import { capitalize } from "../utils/stringUtils";

interface PartnerLinkProps {
  destination?: string;
}

const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const partnerLinks: Record<
  string,
  {
    name: string;
    logo: string;
    links: Record<string, string>;
    defaultLink: string;
  }
> = {
  expedia: {
    name: "Expedia",
    logo: `${baseImageUrl}/logos/expedia-logo.svg`,
    links: {
      dakar: process.env.REACT_APP_EXPEDIA_DAKAR_LINK || "",
      saly: process.env.REACT_APP_EXPEDIA_SALY_LINK || "",
      "saint-louis": process.env.REACT_APP_EXPEDIA_SAINT_LOUIS_LINK || "",
      "cap skirring": process.env.REACT_APP_EXPEDIA_CAP_SKIRRING_LINK || "",
      paris: process.env.REACT_APP_EXPEDIA_PARIS_LINK || "",
      dubai: process.env.REACT_APP_EXPEDIA_DUBAI_LINK || "",
      marrakech: process.env.REACT_APP_EXPEDIA_MARRAKECH_LINK || "",
      istanbul: process.env.REACT_APP_EXPEDIA_ISTANBUL_LINK || "",
    },
    defaultLink: process.env.REACT_APP_EXPEDIA_SEN_DEEP_LINK || "",
  },
  hotels: {
    name: "Hotels.com",
    logo: `${baseImageUrl}/logos/hotels-logo.svg`,
    links: {
      dakar: process.env.REACT_APP_HOTELS_DAKAR_LINK || "",
      saly: process.env.REACT_APP_HOTELS_SALY_LINK || "",
      "saint-louis": process.env.REACT_APP_HOTELS_SAINT_LOUIS_LINK || "",
      "cap skirring": process.env.REACT_APP_HOTELS_CAP_SKIRRING_LINK || "",
      paris: process.env.REACT_APP_HOTELS_PARIS_LINK || "",
      dubai: process.env.REACT_APP_HOTELS_DUBAI_LINK || "",
      marrakech: process.env.REACT_APP_HOTELS_MARRAKECH_LINK || "",
      istanbul: process.env.REACT_APP_HOTELS_ISTANBUL_LINK || "",
    },
    defaultLink: process.env.REACT_APP_HOTELS_SEN_DEEP_LINK || "",
  },
};

const PartnerLink: React.FC<PartnerLinkProps> = ({ destination }) => {
  // Helper to get partner link
  const getLinkForDestination = (
    partner: string,
    destination?: string
  ): string => {
    const partnerData = partnerLinks[partner];
    const lowerCaseDestination = destination?.toLowerCase() || "";

    // Return specific link if found, otherwise the default link
    return partnerData.links[lowerCaseDestination] || partnerData.defaultLink;
  };

  const gaSendPartnerLinkClickEvent = (partnerName: string) => {
    ReactGA.event({
      category: "Partner Link",
      action: "Click",
      label: partnerName,
    });
  };

  // Shuffle the partner links to display them in random order
  const shuffledPartners = Object.keys(partnerLinks).sort(
    () => Math.random() - 0.5
  );

  return (
    <div className="p-6 bg-white border border-gray-300 rounded-md shadow-md text-center mt-6">
      <p className="mb-4 text-gray-700">
        Trouvez les meilleures offres{" "}
        {destination ? `pour ${capitalize(destination)}` : ""} avec nos
        partenaires:
      </p>
      <div className="flex flex-wrap justify-center gap-8">
        {shuffledPartners.map((partner) => (
          <a
            key={partner}
            href={`/redirect?provider=${partner}&to=${getLinkForDestination(
              partner,
              destination
            )}`}
            onClick={() =>
              gaSendPartnerLinkClickEvent(partnerLinks[partner].name)
            }
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center text-center"
          >
            <img
              src={partnerLinks[partner].logo}
              alt={partnerLinks[partner].name}
              className="h-8 w-auto mb-2"
            />
            <span className="text-blue-600 hover:underline">
              {capitalize(partnerLinks[partner].name)}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default PartnerLink;
