import React, { createContext, useContext } from "react";
import {
  senegalDestinations,
  internationalDestinations,
  Destination,
} from "../sharedData/popularDestinations";

// Define the data type for both local and international destinations
interface DestinationContextType {
  senegalDestinations: Destination[];
  internationalDestinations: Destination[];
}

// Create a context with a default value
const DestinationContext = createContext<DestinationContextType | undefined>(
  undefined
);

// Provider component
export const DestinationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <DestinationContext.Provider
      value={{ senegalDestinations, internationalDestinations }}
    >
      {children}
    </DestinationContext.Provider>
  );
};

// Custom hook for easy access to the context
export const useDestinations = () => {
  const context = useContext(DestinationContext);
  if (!context) {
    throw new Error(
      "useDestinations must be used within a DestinationProvider"
    );
  }
  return context;
};
