import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FaBed,
  FaCalendarAlt,
  FaUserFriends,
  FaChild,
  FaDoorClosed,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  topDestinationsLinks,
  IPartnerLinks,
} from "../sharedData/topDestinationsLinks";

interface StaySearchBarProps {
  onSearch?: (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => void;
  hideHeader?: boolean;
  initialValues?: {
    destination?: string;
    checkIn?: string;
    checkOut?: string;
    adults?: number;
    children?: number;
    rooms?: number;
    childAges?: number[];
  };
  isSearchResultPage?: boolean; // New prop to determine if it's used on the SearchResult page
}

const StaySearchBar: React.FC<StaySearchBarProps> = ({
  onSearch,
  hideHeader = false,
  initialValues = {},
  isSearchResultPage = false, // Default to false
}) => {
  const initialValuesAsString = JSON.stringify(initialValues);
  const [destination, setDestination] = useState(
    initialValues.destination || ""
  );
  const [checkInDate, setCheckInDate] = useState<Date | null>(
    initialValues.checkIn ? new Date(initialValues.checkIn) : null
  );
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(
    initialValues.checkOut ? new Date(initialValues.checkOut) : null
  );
  const [adults, setAdults] = useState(initialValues.adults || 2);
  const [children, setChildren] = useState(initialValues.children || 0);
  const [rooms, setRooms] = useState(initialValues.rooms || 1);
  const [childAges, setChildAges] = useState<number[]>(
    initialValues.childAges || []
  );
  const [guestsDropdownOpen, setGuestsDropdownOpen] = useState(false);
  const [isSuggestionsDropdownOpen, setIsSuggestionsDropdownOpen] =
    useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [previousSearchParams, setPreviousSearchParams] = useState(
    initialValuesAsString
  );
  //====== Destination suggestions (autocomplete) state ====
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const [collapsed, setCollapsed] = useState(true); // Collapsing state for mobile view on SearchResult page
  const [honeypot, setHoneypot] = useState(""); // Honeypot field for bots

  const guestsDropdownRef = useRef<HTMLDivElement>(null);
  const suggestionsDropdownRef = useRef<HTMLDivElement>(null);
  const checkInRef = useRef<DatePicker | null>(null);
  const checkOutRef = useRef<DatePicker | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const todaysDate = new Date();

  useEffect(() => {
    //destination suggestions
    setSuggestions(Object.keys(topDestinationsLinks));

    const handleClickOutside = (event: MouseEvent) => {
      if (
        guestsDropdownRef.current &&
        !guestsDropdownRef.current.contains(event.target as Node)
      ) {
        setGuestsDropdownOpen(false);
      }
      if (
        suggestionsDropdownRef.current &&
        !suggestionsDropdownRef.current.contains(event.target as Node)
      ) {
        setIsSuggestionsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDestinationChange = (value: string) => {
    setDestination(value);
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, destination: "" }));
    }

    // Handle destination suggestion
    const query = value.toLowerCase();
    // Filter destinations based on the query
    const filteredSuggestions = Object.keys(topDestinationsLinks).filter(
      (destination) => destination.toLowerCase().includes(query)
    );
    setSuggestions(filteredSuggestions);
    //=============================
  };

  const handleDestinationInputFocus = () => {
    if (suggestions.length > 0) {
      setIsSuggestionsDropdownOpen(true);
    }
  };

  // Handle the selection of a suggestion and set the destination
  const handleSuggestionClick = (destination: string) => {
    setDestination(destination);
    setSuggestions([]); // Clear suggestions after selection
    //Clear prev errors if any
    setErrors((prevErrors) => ({ ...prevErrors, destination: "" }));
  };

  // Check-in Date Input Handler
  const handleCheckInDateChange = (date: Date | null) => {
    setCheckInDate(date);
    if (date) {
      setErrors((prevErrors) => ({ ...prevErrors, checkInDate: "" }));
    }
    // Reset check-out date if it is before the new check-in date
    if (checkOutDate && date && checkOutDate <= date) {
      setCheckOutDate(null);
    }
  };

  // Check-out Date Input Handler
  const handleCheckOutDateChange = (date: Date | null) => {
    setCheckOutDate(date);
    if (date && checkInDate && date > checkInDate) {
      setErrors((prevErrors) => ({ ...prevErrors, checkOutDate: "" }));
    }
  };

  // Handle changes in children count and dynamically create child age inputs
  const handleChildrenChange = (count: number) => {
    setChildren(count);
    // Retain existing values and fill new entries with zeros if needed
    const newAges = [
      ...childAges.slice(0, count), // Keep existing values if count is greater than current length
      ...new Array(Math.max(count - childAges.length, 0)).fill(0), // Fill with zeros if count is increased
    ];
    setChildAges(newAges);
  };

  // Handle change of child age for a specific index
  const handleChildAgeChange = (index: number, age: number) => {
    const newAges = [...childAges];
    newAges[index] = age;
    setChildAges(newAges);

    if (childAges.every((age) => age > 0)) {
      setErrors((prevErrors) => ({ ...prevErrors, childAges: "" }));
    }
  };

  const formatDate = (date: Date | null) =>
    date ? date.toISOString().split("T")[0] : "";

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!destination.trim())
      newErrors.destination = "La destination est requise.";
    if (!checkInDate) newErrors.checkInDate = "La date d'arrivée est requise.";
    if (!checkOutDate)
      newErrors.checkOutDate = "La date de départ est requise.";
    else if (checkInDate && checkOutDate <= checkInDate) {
      newErrors.checkOutDate =
        "La date de départ doit être après la date d'arrivée.";
    }
    if (adults < 1) newErrors.guests = "Il doit y avoir au moins un adulte.";
    if (rooms < 1) newErrors.rooms = "Il doit y avoir au moins une chambre.";
    if (childAges.some((age) => age === 0)) {
      setGuestsDropdownOpen(true);
      //This won't actually be displayed, the age box will have red border instead
      //However this will actually prevent the from submission
      newErrors.childAges = "Âge requis";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const gaSendSearchEvent = () => {
    ReactGA.event({
      category: "Search",
      action: "Submit Search Form",
      label: destination,
      value: 1,
      nonInteraction: false,
    });
  };
  const buildSearchParametersQuery = (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => {
    let query = [];
    let searchParamsQs = "";

    // Add checkIn, checkOut, adults, children, rooms
    if (searchParams.checkIn) query.push(`startDate=${searchParams.checkIn}`);
    if (searchParams.checkOut) query.push(`endDate=${searchParams.checkOut}`);
    if (searchParams.adults) query.push(`adults=${searchParams.adults}`);
    if (searchParams.children) query.push(`children=${searchParams.children}`);
    if (searchParams.rooms) query.push(`rooms=${searchParams.rooms}`);

    if (searchParams.childAges.length > 0) {
      const formattedChildAges = searchParams.childAges
        .map((age) => `1_${age}`)
        .join(",");
      query.push(`children=${formattedChildAges}`);
    }

    // Combine all query parameters into the final URL
    if (query.length > 0) {
      searchParamsQs = `${query.join("&")}`;
    }

    return searchParamsQs;
  };

  const handlePartnerRedirect = (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => {
    // Get the links for the selected destination
    const destinationLinks = topDestinationsLinks[destination];
    // Randomly choose a partner (Expedia or Hotels) from the available partners
    const partners = Object.keys(destinationLinks) as (keyof IPartnerLinks)[];
    const randomPartner = partners[Math.floor(Math.random() * partners.length)];
    const partnerData = destinationLinks[randomPartner];
    let link = partnerData.link;
    const searchParamPlaceholder =
      process.env.REACT_APP_DLINK_SEARCH_PARAMETERS_PLACEHOLDER;

    // Substitute the placeholders in the link
    link = link
      .replace(
        `${searchParamPlaceholder}`,
        buildSearchParametersQuery(searchParams)
      )
      .replace(/&&/g, "&");

    // Redirect to internal redirect page
    window.open(
      `/redirect?provider=${randomPartner}&to=${encodeURIComponent(link)}`,
      "_blank"
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (honeypot) {
      return; // Skip search if honeypot field is filled
    }

    if (!validate()) return;

    const searchParams = {
      destination,
      checkIn: formatDate(checkInDate),
      checkOut: formatDate(checkOutDate),
      adults,
      children,
      rooms,
      childAges,
    };

    const searchString = JSON.stringify(searchParams);

    if (searchString === previousSearchParams) {
      alert(
        "Veuillez modifier les critères de recherche avant de rechercher à nouveau."
      );
      return;
    }

    gaSendSearchEvent();

    setPreviousSearchParams(searchString);

    if (destination in topDestinationsLinks) {
      handlePartnerRedirect(searchParams);
    } else {
      if (location.pathname.startsWith("/sejours") && onSearch) {
        onSearch(searchParams);
      } else {
        navigate(`/sejours/${encodeURIComponent(destination.toLowerCase())}`, {
          state: searchParams,
        });
      }
    }
    // Collapse the search bar after submitting
    // This would only take effect in mobile view, ignored otherwise
    setCollapsed(true);
  };

  return (
    <section className="p-8 bg-gray-100">
      {!hideHeader && (
        <div className="max-w-7xl mx-auto text-center mb-8">
          <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4">
            Trouvez le séjour parfait
          </h1>
          <p className="text-lg text-gray-600">
            <span className="block md:hidden">
              Explorez les meilleures offres.
            </span>
            <span className="hidden md:block">
              Explorez les meilleures offres d'hébergement au Sénégal et dans
              des destinations internationales populaires.
            </span>
          </p>
        </div>
      )}

      <div className="max-w-7xl mx-auto">
        {isSearchResultPage ? (
          <>
            {/* Collapsed Search Controls for Mobile View */}
            <div
              className="md:hidden flex items-center justify-between w-full bg-gray-100 py-2 px-4 rounded-lg border border-gray-300 mt-4"
              onClick={() => setCollapsed(!collapsed)}
            >
              <div className="flex items-center space-x-2 text-gray-700">
                <FaBed className="text-gray-500" />
                <span className="text-sm truncate">
                  {destination || "Destination"}
                </span>

                <FaCalendarAlt className="text-gray-500 ml-3" />
                <span className="text-sm">
                  {checkInDate
                    ? checkInDate.toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                      })
                    : "Arrivée"}{" "}
                  /{" "}
                  {checkOutDate
                    ? checkOutDate.toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                      })
                    : "Départ"}
                </span>

                <FaUserFriends className="text-gray-500 ml-3" />
                <span className="text-sm">{adults + children}</span>

                <FaDoorClosed className="text-gray-500 ml-3" />
                <span className="text-sm">{rooms}</span>
              </div>
              <FaSearch className="text-blue-600" />
            </div>

            {/* Expanded Search Form for Mobile and Desktop Views */}
            {!collapsed && (
              <form
                onSubmit={handleSubmit}
                className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
              >
                {/* Search Form Elements (unchanged) */}
                {renderSearchFormElements()}
              </form>
            )}

            {/* Always Display Search Form on Desktop */}
            <div className="hidden md:block">
              <form
                onSubmit={handleSubmit}
                className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
              >
                {/* Search Form Elements (unchanged) */}
                {renderSearchFormElements()}
              </form>
            </div>
          </>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
          >
            {/* Search Form Elements (unchanged) */}
            {renderSearchFormElements()}
          </form>
        )}
      </div>
    </section>
  );

  // Function to render search form elements to avoid duplication
  function renderSearchFormElements() {
    return (
      <>
        {/* Honeypot input field - hidden from users */}
        <input
          type="text"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          style={{ display: "none" }}
          tabIndex={-1} // Make the field unreachable for users
        />
        {/* Destination Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaBed className="text-gray-500 mr-3" />
            <input
              type="text"
              value={destination}
              onChange={(e) => handleDestinationChange(e.target.value)}
              onFocus={handleDestinationInputFocus}
              placeholder="Destination"
              className="w-full outline-none text-gray-700"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.destination && (
              <p className="text-red-500 text-sm">{errors.destination}</p>
            )}
          </div>
          {/* Suggestions List */}
          {isSuggestionsDropdownOpen && suggestions.length > 0 && (
            <div
              ref={suggestionsDropdownRef}
              className="absolute left-0 top-full mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10"
            >
              {/* Title */}
              <div className="p-3 border-b border-gray-200 bg-gray-50">
                <p className="text-gray-700 font-semibold text-sm">
                  Destinations Populaires
                </p>
              </div>
              {/* List Items */}
              <ul className="max-h-60 overflow-y-auto">
                {/* Only show the top 5 suggestions to avoid vertical scrollbar */}
                {suggestions.slice(0, 5).map((destination) => (
                  <li
                    key={destination}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSuggestionClick(destination)}
                  >
                    {destination}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Check-in Date Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaCalendarAlt
              className="text-gray-500 mr-3 cursor-pointer"
              onClick={() => checkInRef.current?.setFocus()}
            />
            <DatePicker
              selected={checkInDate}
              onChange={handleCheckInDateChange}
              placeholderText="Date d'arrivée"
              className="w-full outline-none text-gray-700"
              ref={checkInRef}
              minDate={todaysDate} // Check-in date must be at least today
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.checkInDate && (
              <p className="text-red-500 text-sm">{errors.checkInDate}</p>
            )}
          </div>
        </div>

        {/* Check-out Date Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaCalendarAlt
              className="text-gray-500 mr-3 cursor-pointer"
              onClick={() => checkOutRef.current?.setFocus()}
            />
            <DatePicker
              selected={checkOutDate}
              onChange={handleCheckOutDateChange}
              placeholderText="Date de départ"
              className="w-full outline-none text-gray-700"
              ref={checkOutRef}
              minDate={
                checkInDate
                  ? new Date(checkInDate.getTime() + 24 * 60 * 60 * 1000)
                  : todaysDate
              } // Check-out date is one day after check-in
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.checkOutDate && (
              <p className="text-red-500 text-sm">{errors.checkOutDate}</p>
            )}
          </div>
        </div>

        {/* Guests Dropdown */}
        <div className="relative w-full md:w-1/4" ref={guestsDropdownRef}>
          <div
            className="flex items-center border border-gray-300 rounded-md p-3 cursor-pointer space-x-2"
            onClick={() => setGuestsDropdownOpen(!guestsDropdownOpen)}
          >
            <span className="text-gray-700 flex items-center space-x-2">
              <FaUserFriends /> <span>{adults}</span>
              <FaChild /> <span>{children}</span>
              <FaDoorClosed /> <span>{rooms}</span>
            </span>
          </div>
          {errors.guests && (
            <p className="text-red-500 text-sm mt-1">{errors.guests}</p>
          )}
          {guestsDropdownOpen && (
            <div className="absolute bg-white border border-gray-300 rounded-lg mt-2 shadow-lg w-full z-10 p-4 c-gdw">
              <div className="flex justify-between items-center mb-2 c-gdi">
                <label className="text-gray-700 c-gdl">Adultes</label>
                <div className="relative flex items-center bg-white border rounded-md w-24">
                  <button
                    type="button"
                    onClick={() => setAdults((prev) => Math.max(1, prev - 1))}
                    disabled={adults <= 1}
                    className={`absolute left-0 p-3 text-blue-600 text-2xl ${
                      adults <= 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    -
                  </button>
                  <span className="w-full p-3 text-center">{adults}</span>
                  <button
                    type="button"
                    onClick={() => setAdults((prev) => prev + 1)}
                    disabled={adults >= 10}
                    className={`absolute right-0 p-3 text-blue-600 text-2xl ${
                      adults >= 10
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center mb-2 c-gdi">
                <label className="text-gray-700 c-gdl">Enfants</label>
                <div className="relative flex items-center bg-white border rounded-md w-24">
                  <button
                    type="button"
                    onClick={() =>
                      handleChildrenChange(Math.max(0, children - 1))
                    }
                    disabled={children <= 0}
                    className={`absolute left-0 p-3 text-blue-600 text-2xl ${
                      children <= 0
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    -
                  </button>
                  <span className="w-full p-3 text-center">{children}</span>
                  <button
                    type="button"
                    onClick={() => handleChildrenChange(children + 1)}
                    disabled={children >= 10}
                    className={`absolute right-0 p-3 text-blue-600 text-2xl ${
                      children >= 10
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    +
                  </button>
                </div>
              </div>
              {/* Child Age Inputs */}
              {children > 0 &&
                childAges.map((age, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mb-2 c-gdi"
                  >
                    <label className="text-gray-700 c-gdl">Âge requis</label>
                    <div
                      className={`relative flex items-center bg-white border rounded-md w-24 ${
                        !age ? "border-red-500" : ""
                      }`}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          handleChildAgeChange(index, Math.max(0, age - 1))
                        }
                        disabled={age <= 0}
                        className={`absolute left-0 p-3 text-blue-600 text-2xl ${
                          age <= 0
                            ? "text-gray-400 cursor-not-allowed"
                            : "hover:text-blue-700"
                        }`}
                      >
                        -
                      </button>
                      <span className="w-full p-3 text-center">{age}</span>
                      <button
                        type="button"
                        onClick={() => handleChildAgeChange(index, age + 1)}
                        disabled={age >= 17}
                        className={`absolute right-0 p-3 text-blue-600 text-2xl ${
                          age >= 17
                            ? "text-gray-400 cursor-not-allowed"
                            : "hover:text-blue-700"
                        }`}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
              <div className="flex justify-between items-center mb-2 c-gdi">
                <label className="text-gray-700 c-gdl">Chambres</label>
                <div className="relative flex items-center bg-white border rounded-md w-24">
                  <button
                    type="button"
                    onClick={() => setRooms((prev) => Math.max(1, prev - 1))}
                    disabled={rooms <= 1}
                    className={`absolute left-0 p-3 text-blue-600 text-2xl ${
                      rooms <= 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    -
                  </button>
                  <span className="w-full p-3 text-center">{rooms}</span>
                  <button
                    type="button"
                    onClick={() => setRooms((prev) => prev + 1)}
                    disabled={rooms >= 10}
                    className={`absolute right-0 p-3 text-blue-600 text-2xl ${
                      rooms >= 10
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setGuestsDropdownOpen(false)}
                  disabled={childAges.some((age) => age === 0)}
                  className={`mt-2 bg-blue-600 text-white font-bold py-1 px-4 rounded-md hover:bg-blue-700 ${
                    childAges.some((age) => age === 0)
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  Terminé
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-600 text-white font-bold py-2 px-6 rounded-md hover:bg-blue-700 transition-all w-full md:w-auto"
        >
          Rechercher
        </button>
      </>
    );
  }
};

export default StaySearchBar;
