import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const RedirectPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectTo = queryParams.get("to");
  const provider = queryParams.get("provider");

  const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const ProviderLogoLookup: { [key: string]: string } = {
    booking: `${baseImageUrl}/logos/booking-logo.svg`,
    expedia: `${baseImageUrl}/logos/expedia-logo.svg`,
    hotels: `${baseImageUrl}/logos/hotels-logo.svg`,
  };

  const getValueByKey = (key: string, defaultValue: string = ""): string => {
    return ProviderLogoLookup[key] || defaultValue; // Return value or default if key doesn't exist
  };

  const providerName = provider as string;
  const providerLogo = getValueByKey(providerName);

  useEffect(() => {
    if (redirectTo) {
      setTimeout(() => {
        window.location.href = redirectTo;
      }, 1000);
    }
  }, [redirectTo]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="text-center max-w-4xl w-full">
        <div className="flex items-center justify-center space-x-4 md:space-x-8">
          {/* SenReservations Section */}
          <div className="flex flex-col items-center">
            {/* 
            <img
              src={`${baseImageUrl}/logos/senreservations-logo.svg`}
              alt="SenReservations Logo"
              className="w-16 h-16 mb-2"
            />
            */}
            <p className="text-gray-800 font-semibold text-lg md:text-3xl mt-2">
              SenReservations
            </p>
            <p className="text-gray-500 text-sm">
              Recherchez et comparez les meilleures offres
            </p>
          </div>
          {/* Chevron Icons Indicating Redirection */}
          <div className="flex items-center justify-center space-x-2">
            <FaChevronRight className="text-blue-500 animate-pulse text-3xl" />
            <FaChevronRight className="text-blue-400 animate-pulse delay-150 text-3xl" />
            <FaChevronRight className="text-blue-300 animate-pulse delay-300 text-3xl" />
          </div>

          {/* Booking Provider Section */}
          <div className="flex flex-col items-center">
            <img src={providerLogo} alt={providerName} />
            {/* 
            <p className="text-gray-800 font-semibold text-lg">
              {providerName}
            </p>
            */}
            <p className="text-gray-500 text-sm">
              Redirection pour consulter les offres et réserver
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
